<template>
  <div class="t-container">
    <div class="t-accounts-msg-container">
          <h1 class="total-accounts-header-msg">
              Welcome Back!
          </h1>
      </div>
    <div class="t-assistant-box">

    <div id="chat_container"></div>
    <div class="form">
        <textarea class="prompt" rows="1" cols="1" placeholder="Ask Tildal..."></textarea> 
        <button class="ask-tildal"><img src="../assets/send.svg" alt="send" /></button>
    </div>
    </div>

  </div>
  
</template>

<script>
import { gsap } from "gsap";
import bot from '../assets/bot.svg';
import user from '../assets/user.svg';
//const form = document.querySelector('.form')
//const chatContainer = document.querySelector('#chat_container')
let loadInterval
export default {
    mounted(){
        
        this.renderWorkAnimationLoadUp();
        this.detectMobileDevice();

        const form = document.querySelector('.prompt')
        const askAI = document.querySelector('.ask-tildal')

        askAI.addEventListener('click', this.handleSubmit)
        form.addEventListener('keyup', (e) => {
            if (e.keyCode === 13) {
                this.handleSubmit(e)
            }
        })
        
    },
    methods:{
        loader(element) {
            element.textContent = ''

            loadInterval = setInterval(() => {
                // Update the text content of the loading indicator
                element.textContent += '.';

                // If the loading indicator has reached three dots, reset it
                if (element.textContent === '....') {
                    element.textContent = '';
                }
            }, 300);
        },
        typeText(element, text) {
            let index = 0

            let interval = setInterval(() => {
                if (index < text.length) {
                    element.innerHTML += text.charAt(index)
                    index++
                } else {
                    clearInterval(interval)
                }
            }, 20)
        },
        generateUniqueId() {
            const timestamp = Date.now();
            const randomNumber = Math.random();
            const hexadecimalString = randomNumber.toString(16);

            return `id-${timestamp}-${hexadecimalString}`;
        },
        chatStripe(isAi, value, uniqueId) {
            return (
                `
                <div class="wrapper ${isAi && 'ai'}">
                    <div class="chat">
                        <div class="profile">
                            <img 
                            src=${isAi ? bot : user} 
                            alt="${isAi ? 'bot' : 'user'}" 
                            />
                        </div>
                        <div class="message" id=${uniqueId}>${value}</div>
                    </div>
                </div>
            `
            )
        },
        async handleSubmit(e){

            
            e.preventDefault()
            const chatContainer = document.querySelector('#chat_container')
            const data = document.querySelector(".prompt");

            // user's chatstripe
            if(data.value === "") return;
            const TO_SEND = data.value
            chatContainer.innerHTML += this.chatStripe(false, data.value)

            // to clear the textarea input 
            data.value = ""

            // bot's chatstripe
            const uniqueId = this.generateUniqueId()
            chatContainer.innerHTML += this.chatStripe(true, " ", uniqueId)

            // to focus scroll to the bottom 
            chatContainer.scrollTop = chatContainer.scrollHeight;

            // specific message div 
            const messageDiv = document.getElementById(uniqueId)

            // messageDiv.innerHTML = "..."
            this.loader(messageDiv)
            const response = await fetch('https://us-central1-jdn-blog.cloudfunctions.net/api', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    prompt: TO_SEND
                })
            })

            clearInterval(loadInterval)
            messageDiv.innerHTML = " "
            
            if (response.ok) {
                const data = await response.json();
                const parsedData = data.bot.trim() // trims any trailing spaces/'\n' 

                this.typeText(messageDiv, parsedData)
            } else {
                const err = await response.text()

                messageDiv.innerHTML = "Something went wrong"
                alert(err)
            }
        },
        detectMobileDevice: function(){
               try{
                   if(document.createEvent("TouchEvent")) {
                       document.querySelector(".total-header-msg").style.paddingTop = "calc(17px*0.9)";
                       document.querySelector(".t-inner-container").style.paddingTop = "0px";
                   }
                } catch(e){ 
                    return false; 
                }
        },
        renderWorkAnimationLoadUp:function() {
            document.documentElement.style.setProperty('--mobilePaint', "#fff");
            gsap.from('.total-accounts-header-msg', {y: -200, duration: 1.27})
            document.querySelectorAll(".t-section a").forEach((attr)=>attr.style.color="#FFF");
            document.querySelector("body").style.backgroundColor = "#353B48";
            document.querySelector(".t-header").style.backgroundColor = document.querySelector('body').style.backgroundColor;
        },
    },


}

</script>

<style lang="scss">
@font-face {
  font-family: "LotaGrotesque";
  src: url('./../assets/Lota-Grotesque-Regular.woff');
}
@font-face {
  font-family: "LotaGrotesque-Thin";
  src: url('./../assets/Lota-Grotesque-Thin.woff');
}
.t-accounts-msg-container{
    color: #fff;
    text-align: center;
    width: 100%;
    max-width:1280px;
    font-size: 72px;
    font-family: LotaGrotesque;
    margin: 0 auto;
}
.total-accounts-header-msg{
    text-align: center;
    width: 100%;
    color: #fff;
    font-size: 72px;
    font-family: LotaGrotesque;
    text-align:initial;
    text-align: center;
}

@keyframes load {
  to {transform: rotate(360deg);}
}
 
.load:before {
  content: '';
  box-sizing: border-box;
  width: 70px;
  height: 70px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: load .6s linear infinite;
  margin: 0 auto;
}


#chat_container {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
    
  display: flex;
  flex-direction: column;
  gap: 10px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  padding-bottom: 20px;
  scroll-behavior: smooth;
}

/* hides scrollbar */
#chat_container::-webkit-scrollbar {
  display: none;
}

.wrapper {
  width: 100%;
  padding: 15px;
}

.ai {
  background: #40414F;
}

.chat {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

.profile {
  width: 36px;
  height: 36px;
  border-radius: 5px;

  background: #5436DA;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ai .profile {
  background: #10a37f;
}

.profile img {
  width: 60%;
  height: 60%;
  object-fit: contain;
}

.message {
  flex: 1;
    text-align: start;
  color: #dcdcdc;
  font-size: 12px;

  max-width: 100%;
  overflow-x: scroll;

  /*
   * white space refers to any spaces, tabs, or newline characters that are used to format the CSS code
   * specifies how white space within an element should be handled. It is similar to the "pre" value, which tells the browser to treat all white space as significant and to preserve it exactly as it appears in the source code.
   * The pre-wrap value allows the browser to wrap long lines of text onto multiple lines if necessary.
   * The default value for the white-space property in CSS is "normal". This tells the browser to collapse multiple white space characters into a single space, and to wrap text onto multiple lines as needed to fit within its container.
  */
  white-space: pre-wrap; 

  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* hides scrollbar */
.message::-webkit-scrollbar {
  display: none;
}

.form {
  margin: 0 auto;
  padding: 10px;
  background: #40414F;

  display: flex;
  flex-direction: row;
  gap: 10px;
}

textarea {
  width: 100%;

  color: #fff;
  font-size: 18px;

  padding: 10px;
  background: transparent;
  border-radius: 5px;
  border: none;
  outline: none;
}

button {
  outline: 0;
  border: 0;
  cursor: pointer;
  background: transparent;
}

.form img {
  width: 30px;
  height: 30px;
}
.t-assistant-box {
    width: 70%;
    margin: 0 auto;
}
</style>